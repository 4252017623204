import React from 'react';
import Feature from '../../components/sections/industry/feature-block';
import { useStaticQuery, graphql } from 'gatsby';
import WhyPatronscan from '../../components/sections/why-patronscan';
import { Benefits } from '../../models/benefit';
import BenefitSection from '../../components/sections/industry/benefit';
import SEO from '../../components/seo';
import ConnectWithUs from '../../components/sections/connect-with-us';
import { Route } from '../../models/site-links';
import './telecom.css';
import HeroBanner from '../../components/sections/hero-banner';
import { getDemoLink } from '../../services/demo-selector';

const Telecom = () => {
  const data = useStaticQuery(graphql`
    query {
      countertopPlus: file(relativePath: { eq: "product-card/countertop-white.png" }) {
        childImageSharp {
          fixed(height: 118) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      kioskPlus: file(relativePath: { eq: "product-card/kiosk-plus.png" }) {
        childImageSharp {
          fixed(height: 118) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      fakes: file(relativePath: { eq: "feature-images/fakes.jpg" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIds: file(relativePath: { eq: "feature-images/fake-ids.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      whiteScanner: file(relativePath: { eq: "feature-images/white-scanner.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      blackCountertop: file(relativePath: { eq: "benefit-images/black-countertop.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      carRentalAlert: file(relativePath: { eq: "benefit-images/car-rental-alert.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      barcodeScanning: file(relativePath: { eq: "feature-images/barcode_no_bueno.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      kiosk: file(relativePath: { eq: "feature-images/white-scanner.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      spaceSavingDesign: file(relativePath: { eq: "telco/spaceSaving.png" }) {
        childImageSharp {
          fluid(quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fakeIDBanner: file(relativePath: { eq: "hero-images/fakeID-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fraudster: file(relativePath: { eq: "anti-fraud/Fraudster-latest.png" }) {
        childImageSharp {
          fluid(maxWidth: 3160, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  const benefits: Benefits[] = [
    {
      image: data.blackCountertop.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Advanced Multi-Layer ID Verification',
          text: 'Fake IDs get better everyday, so does Patronscan.  Our proprietary software scans the front of the ID and identifies which IDs are real and which are fake. Scanners that only scan barcodes are easily fooled. Using Patronscan is the best way to protect yourself from fraudsters.',
        },
        {
          heading: 'Read All Types of Identification',
          text: 'You want your ID scanner to be as effective as possible, which means scanning all types of IDs. We scan all types of government issues IDs including: driver’s licenses, military cards, passports and international IDs. Patronscan has the ability to scan over 4,500 different IDs from around the world.',
        },
        {
          heading: 'High Speed Scanning',
          text: 'Patronscan makes validating IDs simple. In 3-4 seconds your staff will know everything they need to about the potential customer standing in front of them. Verify your customers identity without creating more work for your staff.',
        },
      ],
    },
    {
      image: data.carRentalAlert.childImageSharp.fluid,
      benefits: [
        {
          heading: 'Powerful Shared Flag Network',
          text: 'Identifying fraudsters before they act is difficult. The Patronscan network will alert you when someone has been flagged by another location for fraudulent behavior.  Your locations are notified of exactly when and why the flag was placed, preventing further damages.',
        },
        {
          heading: 'Real-Time Alerts',
          text: 'There are times when you need information immediately. Staff can send out real time text message alerts from our system.',
        },
      ],
    },
  ];

  let sectionCounter = 0;
  return (
    <div>
      <SEO
        title="ID Scanners for Cell Phone Stores | Patronscan"
        description="Fake IDs are better than ever. Spot fake IDs and known fraudsters with Patronscan’s advanced ID scanners. Prevent hardware theft and SIM swap fraud."
      />
      <section>
        <HeroBanner
          heroImage={data.fakeIDBanner.childImageSharp.fluid}
          heading="Trusted ID Verification for Telecommunications"
          subHeading="Catch fake IDs and deter fraud with the world's best ID scanners."
          videoLink="https://player.vimeo.com/video/366101059?autoplay=1&loop=1&autopause=0&muted=1"
          showVideo
          linkText="Book Demo"
          link={getDemoLink()}
          buttonId="TelecomHero:BookDemo"
        />
        <Feature
          image={data.fraudster.childImageSharp.fluid}
          heading="Most fraud runs"
          headingThin="through fake IDs"
          subHeading="Synthetic Identity fraud is one of the fastest-growing types of ID fraud in the world."
          description="Fraudsters use a combination of real and fake personal data to create identities to defraud telecommunication providers. They rely on fake IDs to steal hardware and SIM cards, costing millions in fraud."
          linkText="Contact Us"
          link={Route.TalkToAnExpert}
          buttonId="TelecomFeature:MostFraud"
        />
        <Feature
          rightAlign
          image={data.barcodeScanning.childImageSharp.fluid}
          heading="Barcode scanning"
          headingThin="is not good enough"
          subHeading="Fake ID websites guarantee that their IDs will pass any barcode scanner or your money back."
          description="A barcode is only one point of reference. Our authentication process compares over 6,000 data points to verify it is real. With Patronscan you can be confident that you’re catching quality fakes."
          linkText="Book Demo"
          link={getDemoLink()}
          buttonId="TelecomFeature:BarcodeScanningIsNoGood"
        />
        <Feature
          image={data.spaceSavingDesign.childImageSharp.fluid}
          heading="Sleek, modern and"
          headingThin="space-saving design"
          subHeading="Our technology uses a sophisticated design that fits nicely into your operations."
          description="The easy to use interface allows your staff to quickly scan and identify whether an ID is valid, all while providing an excellent customer experience."
          linkText="Get Pricing"
          link={Route.GetPricing}
          buttonId="TelecomFeature:SleekDesign"
        />
      </section>
      <section>
        <h2 className="p-4 lg:p-16 gradient-text text-3xl lg:text-6xl leading-tight text-center font-hairline font-heading">
          More Patronscan Benefits
        </h2>
      </section>
      <section>
        {benefits.map((benefit) => {
          sectionCounter++;
          return (
            <BenefitSection benefits={benefit} alignRight={sectionCounter % 2 ? true : false} />
          );
        })}
      </section>
      <div className="telecom-why-patronscan">
        <WhyPatronscan />
      </div>
      <ConnectWithUs
        buttonId="TelecomConnect"
        promptText="Get in touch to get pricing information or see how Patronscan protects telecommunication providers from fraud."
      />
    </div>
  );
};

export default Telecom;
